import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { EmptyPlaceholder, Preloader } from '../../../../common';
import { Table } from '../../../../bidding/common/table';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { bankArrangerPipelineColumns } from './bankArrangerPipeline.columns';
import { useArrangerPipeline } from '../../../hooks';
import { isRequesting } from '../../../../../utils';
import { amrArrangerPipelineActions as actions } from '../../../../../actions/amr-arranger-pipeline.actions';
import IconSVG from '../../../../../styles/svg-icons';
import { QueryStringArgumentSerializer } from '../../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { arrangers, imPipelineTab } from '../../../../../utils/filtering/serializers/serializer.definitions';
import { IssuanceMonitorTab } from '../../../../amrPipeline/types/PipelineTabTypes';
import { queryStringSerializer } from '../../../../../utils/filtering';
import { routes } from '../../../../../constants';
import { BankAccessType } from '../../../../../types/amr-pipeline/enums/BankAccessType';
import { banksActions } from '../../../../../actions/banks.actions';
import { CloManagerAccessType } from '../../../../../types/amr-pipeline/enums/CloManagerAccessType';
import { RequiredFeature } from '../../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from '../../../../access/BlockedFeatureText';
import { useContext } from 'react';
import BanksContext from '../../../banksContext';

interface BankArrangerPipelineProps {
    companyReferenceName: string;
}

export const BankArrangerPipeline = ({ companyReferenceName }: BankArrangerPipelineProps) => {
    const dispatch = useDispatch();
    const { ownCompany, hasProArrangerSubscripion } = useContext(BanksContext);
    const { requestState, pipelines } = useArrangerPipeline(companyReferenceName);

    const redirectToArrangerPipeline = () => {
        const searchParams = {
            arrangers: [companyReferenceName],
            imPipelineTab: IssuanceMonitorTab.ArrangerPipeline,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            imPipelineTab(),
        ];

        const queryString = queryStringSerializer.serialize(searchParams, serializer);

        dispatch(banksActions.logUserActivity(companyReferenceName, BankAccessType.PrimaryArrangerPipelineIssuanceMonitor));

        window.open(`${window.location.origin}${routes.AMRPipeline}/?${queryString}`);
    };

    const logColumnClick = (bankAccessType: BankAccessType) => () => {
        dispatch(banksActions.logUserActivity(companyReferenceName, bankAccessType));
    };

    return (
        <SectionContent
            title="Arranger Pipeline"
            actionBtn={
                <button className="btn-link" onClick={redirectToArrangerPipeline}>
                    <IconSVG name="go-to" width={16} height={16} />
                    Arranger Pipeline
                </button>
            }
            className="arranger-pipeline"
        >
            <Preloader inProgress={isRequesting(requestState)}>
                <RequiredFeature
                    feature={SubscriptionFeature.DealerProfileArrangerPipeline}
                    overrideRequiredFeatures={hasProArrangerSubscripion || ownCompany}
                    renderBlockedContent={() =>
                        <BlockedFeatureContent
                            inline
                            className="empty-placeholder analytics-legend"
                            text={<><SubscribeLink /> to see the deals.</>}
                        />
                    }
                >
                    {pipelines.length ? (
                        <Table
                            className={classNames('data-list-striped data-list-arranger-pipeline')}
                            dataItems={pipelines}
                            columns={bankArrangerPipelineColumns}
                            createSecurityCustomArgs={() => ({
                                dispatch,
                                actions,
                                onManagerClick: logColumnClick(BankAccessType.PrimaryArrangerPipelineManagersName),
                                managerPresentationAccessType: CloManagerAccessType.DownloadManagerPresentationFileFromBank,
                                bankReferenceName: companyReferenceName,
                            })}
                        />
                    ) : (
                        <EmptyPlaceholder text="There are no transactions yet." />
                    )}
                </RequiredFeature>
            </Preloader>
        </SectionContent>
    );
}
